// @ts-nocheck
import winston from "winston";

const {createLogger, format, transports} = winston;
import DailyRotateFile from 'winston-daily-rotate-file';

// Add a custom mask function to mask sensitive data
const maskSensitiveData = (info: any) => {
  // Implement your logic to mask sensitive data here
  // For example, you can use a library like 'mask-sensitive-data'
  if (/(name|email|pictureUrl)%22:%22*(.*?)%22%2C/.test(info.message)) {
    info.message = info.message.replace(/(name|email|pictureUrl)%22:%22*(.*?)%22%2C/g, '$1:%22:%22********%22%2C');
  }
  if (/\w+@\w+\.\w+/.test(info.message)) {
    info.message = info.message.replace(/\w+@\w+\.\w+/g, '***@****');
  }
  if (/authKey=(.*?),/.test(info.message)) {
    info.message = info.message.replace(/authKey=(.*?),/g, 'authKey=********,');
  }
  if (/x-external-token=(.*?),/.test(info.message)) {
    info.message = info.message.replace(/x-external-token=(.*?),/g, 'x-external-token=********,');
  }
  if (/"(userPhoneNumber|userPhone|phoneNumber|name|pictureUrl)\\*"(\s*):(.*?),/g.test(info.message)) {
    info.message = info.message.replace(/"(userPhoneNumber|userPhone|phoneNumber|name|pictureUrl)\\*"(\s*):(.*?),/, '"$1"$2:********,');
  }
  if (/"(userEmail|email)\\*"(\s*):(.*?),/.test(info.message)) {
    info.message = info.message.replace(/"(userEmail|email)\\*"(\s*):(.*?),/g, '"$1"$2:********,');
  }
  return info;
};

// Create a log format that includes timestamp and log level
const logFormat = format.combine(
  format(maskSensitiveData)(),
  format.timestamp({
    format: 'YYYY-MM-DD HH:mm:ss',
  }),
  format.errors({stack: true}),
  format.splat(),
  format.json(),
);
// // Add the maskSensitiveData function to the logger
// logger.add(new winston.transports.Console({
//   format: format.combine(
//     format(maskSensitiveData)(),
//     format.simple()
//   ),
// }));

// Create a transport for console logging
const consoleTransport = new transports.Console({
  format: format.combine(format.colorize(), logFormat),
});

// Create a transport for rotating file logging
let fileTransportInfo;
let fileTransportError;

if (typeof window === 'undefined' && process.env.NEXT_PUBLIC_ENVIRONMENT !== 'local') {
  fileTransportInfo = new DailyRotateFile({
    level: 'info',
    dirname: '/var/app/log/web-commerce-nextjs',
    filename: 'app-%DATE%.log',
    datePattern: 'YYYY-MM-DD',
    zippedArchive: true,
    maxSize: '5g', // Maximum log file size before rotation
    maxFiles: '14d', // Retain logs for 14 days
    format: logFormat,
  });
  fileTransportError = new DailyRotateFile({
    level: 'error',
    dirname: '/var/app/log/web-commerce-nextjs',
    filename: 'error-%DATE%.log',
    datePattern: 'YYYY-MM-DD',
    zippedArchive: true,
    maxSize: '5g', // Maximum log file size before rotation
    maxFiles: '14d', // Retain logs for 14 days
    format: logFormat,
  });
}

const transportsArr = [consoleTransport];

if (fileTransportInfo) {
  transportsArr.push(fileTransportInfo);
}
if (fileTransportError) {
  transportsArr.push(fileTransportError);
}

// Create a Winston logger
const logger = createLogger({
  transports: transportsArr,
});

export default logger;