import { apiCall } from "utils/api";
const AUTH_TOKEN = `${process.env.NEXT_PUBLIC_AUTH_TOKEN}`;
const SHOP_API_HOST = `${process.env.NEXT_PUBLIC_SHOP_API_HOST}`;

export const fetchMainFeed = async ({ guestUserId, paginationKey }: any) => {
  const res = await apiCall({
    method: "get",
    url: `${SHOP_API_HOST}/mainFeed?guestUserId=${guestUserId}&paginationKey=${paginationKey}`,
    headers: {
      Authorization: AUTH_TOKEN,
      'Cache-Control': 'no-cache',
    },
  });
  return { ...res.data };
};

export const fetchCollection = async ({
  guestUserId,
  paginationKey,
  collectionId,
}: any) => {
  const res = await apiCall({
    method: "get",
    url: `${SHOP_API_HOST}/collection?guestUserId=${guestUserId}&paginationKey=${paginationKey}&collectionId=${collectionId}&limit=10`,
    headers: {
      Authorization: AUTH_TOKEN,
    },
  });
  return { ...res.data };
};

export const fetchCfts = async ({ guestUserId, paginationKey = 0 }: any) => {
  const res = await apiCall({
    method: "get",
    url: `${SHOP_API_HOST}/cfts?guestUserId=${guestUserId}&paginationKey=${paginationKey}`,
    headers: {
      Authorization: AUTH_TOKEN,
    },
  });
  return { ...res.data };
};

export const fetchCartCount = async ({ guestUserId }: any) => {
  const res = await apiCall({
    method: "get",
    url: `${SHOP_API_HOST}/cartCount?guestUserId=${guestUserId}`,
    headers: {
      Authorization: AUTH_TOKEN,
    },
  });
  return { ...res.data };
};

export const fetchNavigationMenu = async (guestUserId: any) => {
  const res = await apiCall({
    method: "get",
    url: `${SHOP_API_HOST}/navigationDrawer?guestUserId=${guestUserId}`,
    headers: {
      Authorization: AUTH_TOKEN,
    },
  });
  return { ...res.data };
};

export const setGuestUserId = async (payload: any) => {
  const res = await apiCall({
    method: "put",
    url: `${SHOP_API_HOST}/user`,
    headers: {
      Authorization: AUTH_TOKEN,
    },
    data: payload,
  });
  return { ...res.data };
};

export const getOnboardingData = async (guestUserId: string) => {
  const res = await apiCall({
    method: "get",
    url: `${SHOP_API_HOST}/onboarding?guestUserId=${guestUserId}`,
    headers: {
      Authorization: AUTH_TOKEN,
    },
  });
  return { ...res.data };
};

interface Props {
  paginationKey: number;
  guestUserId?: string;
  productId?: string;
}

export const fetchShoptabVideoFeed = async ({
  paginationKey,
  guestUserId,
  productId,
}: Props) => {
  const res = await fetch(
    `${SHOP_API_HOST}/videoFeed?paginationKey=${paginationKey}&limit=5${
      productId ? `&productId=${productId}` : ""
    }${guestUserId ? `&guestUserId=${guestUserId}` : ""}`,
    {
      headers: {
        Authorization: `${process.env.shoptab_api_user_credential}` || "",
      },
    }
  );
  const data = await res.json();
  return data;
};

export const fetchConfig = async (guestUserId: string) => {
  const res = await fetch(
    `${SHOP_API_HOST}/config?guestUserId=${guestUserId}`
  );
  const data = await res.json();
  return data;
};
