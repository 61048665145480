// ety - event type (type column of the sheet)//will be app if in web view other wise pwa
// act - action
// ctx: context
// src: source
// ts: timestamp
// app_sid: app session id
// ext: extras
// anon: anonymous
// or: origin
// pr_id: product id
// pr_nm: product name
// vr_id: variant id
// vr_nm: variant name
// or_id: order id
// crt_id: cart id
// psid: popshop id
// ps_sid: popshop session id
// crid: creator id

import { COOKIES, getCookie } from "utils/cookies";
import { WindowInterface } from "types";
import {
  getGlanceSdkVersion,
  isGlance,
  isGlanceLocked,
  isRoposo,
} from "./app-native";
import {
  getGlanceId,
  getGpId,
  getGuestUserId,
  getSessionId,
  handleCreateSessionId,
  isWebView,
  removeUndefinedNull,
  getCurrentPageName,
  getClickSessionInfo,
  getPageSessionInfo,
  getQueryParamObject,
} from "./misc";
import { v4 as uuidv4 } from "uuid";

const myWindow: WindowInterface | null =
  typeof window !== "undefined" ? window : null;

const ROPOSO_ANALYTICS_NAMES = {
  eventType: "ety",
  pageContext: "ctx",
  extras: "extras",
  origin: "or",
  action: "act",
  source: "src",
  popShopSessionId: "sid", // used to be ps_sid
  productId: "pr_id",
  productName: "pr_nm",
  variantId: "vr_id",
  variantName: "vr_nm",
  cartId: "crt_id",
  creatorId: "crid",
  orderId: "or_id",
  expertEnabled: "expert_enabled",
  buttonExpanded: "button_expanded",
  timeLeftForUnlock: "time_left_for_unlock",
  orderAmount: "orderamount",
  paymentMode: "paymentmode",
  numberOfProducts: "numberofproducts",
  glanceUserId: "puid",
  userId: "uid",
  ext: "ext",
  timestamp: "ts",
  appversion: "appversion",
  clickSessionId: "click_session_id",
  pageSessionId: "page_session_id",
  duration: "duration",
};

export const CheckoutEvent = {
  eventType: ROPOSO_ANALYTICS_NAMES.eventType,
  pageContext: ROPOSO_ANALYTICS_NAMES.pageContext,
  extras: ROPOSO_ANALYTICS_NAMES.extras,
  origin: ROPOSO_ANALYTICS_NAMES.origin,
  action: ROPOSO_ANALYTICS_NAMES.action,
  source: ROPOSO_ANALYTICS_NAMES.source,
  popShopSessionId: ROPOSO_ANALYTICS_NAMES.popShopSessionId,
  creatorId: ROPOSO_ANALYTICS_NAMES.creatorId,
  productId: ROPOSO_ANALYTICS_NAMES.productId,
  productName: ROPOSO_ANALYTICS_NAMES.productName,
  variantId: ROPOSO_ANALYTICS_NAMES.variantId,
  variantName: ROPOSO_ANALYTICS_NAMES.variantName,
  cartId: ROPOSO_ANALYTICS_NAMES.cartId,
  orderAmount: ROPOSO_ANALYTICS_NAMES.orderAmount,
  paymentMode: ROPOSO_ANALYTICS_NAMES.paymentMode,
  numberOfProducts: ROPOSO_ANALYTICS_NAMES.numberOfProducts,
};

export const ProductEvent = {
  eventType: ROPOSO_ANALYTICS_NAMES.eventType,
  pageContext: ROPOSO_ANALYTICS_NAMES.pageContext,
  extras: ROPOSO_ANALYTICS_NAMES.extras,
  origin: ROPOSO_ANALYTICS_NAMES.origin,
  action: ROPOSO_ANALYTICS_NAMES.action,
  source: ROPOSO_ANALYTICS_NAMES.source,
  popShopSessionId: ROPOSO_ANALYTICS_NAMES.popShopSessionId,
  creatorId: ROPOSO_ANALYTICS_NAMES.creatorId,
  productId: ROPOSO_ANALYTICS_NAMES.productId,
  productName: ROPOSO_ANALYTICS_NAMES.productName,
  variantId: ROPOSO_ANALYTICS_NAMES.variantId,
  variantName: ROPOSO_ANALYTICS_NAMES.variantName,
  expertEnabled: ROPOSO_ANALYTICS_NAMES.expertEnabled,
  buttonExpanded: ROPOSO_ANALYTICS_NAMES.buttonExpanded,
  timeLeftForUnlock: ROPOSO_ANALYTICS_NAMES.timeLeftForUnlock,
  glanceUserId: ROPOSO_ANALYTICS_NAMES.glanceUserId,
  userId: ROPOSO_ANALYTICS_NAMES.userId,
  timestamp: ROPOSO_ANALYTICS_NAMES.timestamp,
};

export const ProfileEvent = {
  eventType: ROPOSO_ANALYTICS_NAMES.eventType,
  pageContext: ROPOSO_ANALYTICS_NAMES.pageContext,
  extras: ROPOSO_ANALYTICS_NAMES.extras,
  origin: ROPOSO_ANALYTICS_NAMES.origin,
  action: ROPOSO_ANALYTICS_NAMES.action,
  source: ROPOSO_ANALYTICS_NAMES.source,
  popShopSessionId: ROPOSO_ANALYTICS_NAMES.popShopSessionId,
  orderId: ROPOSO_ANALYTICS_NAMES.orderId,
  productId: ROPOSO_ANALYTICS_NAMES.productId,
  productName: ROPOSO_ANALYTICS_NAMES.productName,
  variantId: ROPOSO_ANALYTICS_NAMES.variantId,
  variantName: ROPOSO_ANALYTICS_NAMES.variantName,
};

export const ImpressionEvent = {
  eventType: ROPOSO_ANALYTICS_NAMES.eventType,
  pageContext: ROPOSO_ANALYTICS_NAMES.pageContext,
  extras: ROPOSO_ANALYTICS_NAMES.ext,
  origin: ROPOSO_ANALYTICS_NAMES.origin,
  action: ROPOSO_ANALYTICS_NAMES.action,
  source: ROPOSO_ANALYTICS_NAMES.source,
  popShopSessionId: ROPOSO_ANALYTICS_NAMES.popShopSessionId,
  creatorId: ROPOSO_ANALYTICS_NAMES.creatorId,
  productId: ROPOSO_ANALYTICS_NAMES.productId,
  productName: ROPOSO_ANALYTICS_NAMES.productName,
  variantId: ROPOSO_ANALYTICS_NAMES.variantId,
  variantName: ROPOSO_ANALYTICS_NAMES.variantName,
  expertEnabled: ROPOSO_ANALYTICS_NAMES.expertEnabled,
  buttonExpanded: ROPOSO_ANALYTICS_NAMES.buttonExpanded,
  timeLeftForUnlock: ROPOSO_ANALYTICS_NAMES.timeLeftForUnlock,
  glanceUserId: ROPOSO_ANALYTICS_NAMES.glanceUserId,
  userId: ROPOSO_ANALYTICS_NAMES.userId,
  appversion: ROPOSO_ANALYTICS_NAMES.appversion,
};

export const EngagementEvent = {
  eventType: ROPOSO_ANALYTICS_NAMES.eventType,
  pageContext: ROPOSO_ANALYTICS_NAMES.pageContext,
  extras: ROPOSO_ANALYTICS_NAMES.ext,
  origin: ROPOSO_ANALYTICS_NAMES.origin,
  action: ROPOSO_ANALYTICS_NAMES.action,
  source: ROPOSO_ANALYTICS_NAMES.source,
  popShopSessionId: ROPOSO_ANALYTICS_NAMES.popShopSessionId,
  creatorId: ROPOSO_ANALYTICS_NAMES.creatorId,
  productId: ROPOSO_ANALYTICS_NAMES.productId,
  productName: ROPOSO_ANALYTICS_NAMES.productName,
  variantId: ROPOSO_ANALYTICS_NAMES.variantId,
  variantName: ROPOSO_ANALYTICS_NAMES.variantName,
  expertEnabled: ROPOSO_ANALYTICS_NAMES.expertEnabled,
  buttonExpanded: ROPOSO_ANALYTICS_NAMES.buttonExpanded,
  timeLeftForUnlock: ROPOSO_ANALYTICS_NAMES.timeLeftForUnlock,
  glanceUserId: ROPOSO_ANALYTICS_NAMES.glanceUserId,
  userId: ROPOSO_ANALYTICS_NAMES.userId,
  appversion: ROPOSO_ANALYTICS_NAMES.appversion,
};

export const ShoptabEvents = {
  eventType: ROPOSO_ANALYTICS_NAMES.eventType,
  pageContext: ROPOSO_ANALYTICS_NAMES.pageContext,
  extras: ROPOSO_ANALYTICS_NAMES.extras,
  origin: ROPOSO_ANALYTICS_NAMES.origin,
  action: ROPOSO_ANALYTICS_NAMES.action,
  source: ROPOSO_ANALYTICS_NAMES.source,
  popShopSessionId: ROPOSO_ANALYTICS_NAMES.popShopSessionId,
  creatorId: ROPOSO_ANALYTICS_NAMES.creatorId,
  productId: ROPOSO_ANALYTICS_NAMES.productId,
  productName: ROPOSO_ANALYTICS_NAMES.productName,
  variantId: ROPOSO_ANALYTICS_NAMES.variantId,
  variantName: ROPOSO_ANALYTICS_NAMES.variantName,
  expertEnabled: ROPOSO_ANALYTICS_NAMES.expertEnabled,
  buttonExpanded: ROPOSO_ANALYTICS_NAMES.buttonExpanded,
  timeLeftForUnlock: ROPOSO_ANALYTICS_NAMES.timeLeftForUnlock,
  glanceUserId: ROPOSO_ANALYTICS_NAMES.glanceUserId,
  userId: ROPOSO_ANALYTICS_NAMES.userId,
  appversion: ROPOSO_ANALYTICS_NAMES.appversion,
  timestamp: ROPOSO_ANALYTICS_NAMES.timestamp,
  clickSessionId: ROPOSO_ANALYTICS_NAMES.clickSessionId,
  pageSessionId: ROPOSO_ANALYTICS_NAMES.pageSessionId,
  duration: ROPOSO_ANALYTICS_NAMES.duration,
};

export const EventTypeInt = {
  PRODUCT: 107,
  CHECKOUT: 108,
  PROFILE: 109,
  IMPRESSION: 110,
  ENGAGEMENT: 111,
  SHOPTAB: 112,
};

export const eventBucket: { [key: number]: string } = {
  107: "productEvents",
  108: "checkoutEvents",
  109: "profileEvents",
  110: "impressionEvents",
  111: "engagementEvents",
  112: "shoptabEvents",
};

export const eventActionType = {
  IMPRESSION: "impression",
  CLICK: "click",
};

export const shoptabContext = {
  MAIN_PAGE: "main_page",
  HEADER: "header",
  GENDER_SELECT: "gender_select",
  TRENDS_PAGE: "trends_page",
  SIDE_MENU_BAR: "side_menu_bar",
  PLAY_AND_WIN: "play_and_win",
  VIDEO_FEED: "video_feed",
  PRODUCT_PAGE: "product_page",
  ONBOARDING: "onboarding",
};

export const shoptabAction = {
  PAGE_VIEWED: "page_viewed",
  PRODUCT_CARD_CLICK: "product_card_clicked",
  VIDEO_CARD_CLICKED: "video_card_clicked",
  TREND_HEADLINE_CLICKED: "trend_headline_clicked",
  CFT_CLICKED: "cft_clicked",
  JUST_LAUNCHED_CLICKED: "just_launched_clicked",
};

let sId: string = "";
let uId: string = "";

export const trackEventForInmobi = (
  eventTypeInt: number,
  event: any,
  headers: any = {},
  isShopTabEvent = false,
) => {
  // if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'performance') {
  //   return;
  // }
  try {
    const src = event.src || getCookie(null, COOKIES.src);
    let extras: any = "";
    if (eventTypeInt === 110 || eventTypeInt === 111) {
      extras = {
        live_session_id:
          src === "live_feed" ? getCookie(null, COOKIES.sessionId) : undefined,
        stream_id: getCookie(null, COOKIES.streamId),
        story_id: getCookie(null, COOKIES.storyId),
        ...event[ImpressionEvent.extras],
      };
      extras = Object.keys(extras).reduce(
        (previous, current) => ({
          ...previous,
          [current]: extras[current] && String(extras[current]),
        }),
        {}
      );
    } else {
      extras = JSON.stringify({
        live_session_id:
          src === "live_feed" ? getCookie(null, COOKIES.sessionId) : null,
        stream_id: getCookie(null, COOKIES.streamId),
        story_id: getCookie(null, COOKIES.storyId),
        ...event[ROPOSO_ANALYTICS_NAMES.extras],
      });
    }

    const eventWithQueryParamPassedInfo = {
      src,
      gpid: getCookie(null, COOKIES.gpid),
      sessionid: getCookie(null, COOKIES.sessionId),
      sid: getCookie(null, COOKIES.sessionId),
      creatorid: getCookie(null, COOKIES.externalCreatorId),
      crid: getCookie(null, COOKIES.externalCreatorId),
      uid: getCookie(null, COOKIES.deviceId),
      ...event,
      [[110, 111].includes(eventTypeInt)
        ? ImpressionEvent.extras
        : ROPOSO_ANALYTICS_NAMES.extras]: extras,
    };
    if (!isShopTabEvent && myWindow?.appEventsBridge && myWindow?.appEventsBridge.logEventToRNA) {
      myWindow?.appEventsBridge.logEventToRNA(
        eventTypeInt,
        JSON.stringify({
          [ProductEvent.origin]: "app",
          ...eventWithQueryParamPassedInfo,
        })
      );
    } else if (
      !isShopTabEvent &&
      myWindow?.webkit &&
      myWindow?.webkit.messageHandlers &&
      myWindow?.webkit.messageHandlers.liveBridge &&
      myWindow?.webkit.messageHandlers.liveBridge.postMessage
    ) {
      myWindow?.webkit.messageHandlers.liveBridge.postMessage(
        JSON.stringify({
          destination: 1,
          event: eventBucket[eventTypeInt],
          data: {
            [ProductEvent.origin]: "app",
            ...eventWithQueryParamPassedInfo,
            [ProductEvent.productId]: eventWithQueryParamPassedInfo[
              ProductEvent.productId
            ]
              ? eventWithQueryParamPassedInfo[ProductEvent.productId].toString()
              : null,
          },
        })
      );
    } else {
      console.log({
        [ProductEvent.origin]: "pwa",
        ...eventWithQueryParamPassedInfo,
      });
      // if (
      //   process.env.NEXT_PUBLIC_ENVIRONMENT === "production" &&
      //   location.host !== "store.roposo.com"
      // ) {
      //   return;
      // }
      try {
        fetch(`${process.env.NEXT_PUBLIC_ANALYTICS_EVENT_HOST}`, {
          method: "post",
          headers: {
            uid: eventWithQueryParamPassedInfo.uid,
            env: "pwa",
            ...headers,
          },
          body: JSON.stringify({
            [eventBucket[eventTypeInt]]: [
              {
                [ProductEvent.origin]: "pwa",
                [ProductEvent.timestamp]: Date.now(),
                ...eventWithQueryParamPassedInfo,
              },
            ],
          }),
        });
      }
      catch (e) {
        console.log("Failed to fetch and track RNA event", e);
      }

    }
  } catch (e) {
    console.error("Failed to track RNA event", e);
  }
};

const getSID = () => {
  if (!sId) {
    sId = getSessionId();
  }
  if (!sId) {
    handleCreateSessionId();
    sId = getSessionId();
  }
  return sId;
};

const getUID = () => {
  if (!uId) {
    uId = getGuestUserId();
  }
  return uId;
};

export const trackEventForShoptab = (router: any, event: any) => {
  const {
    utm_redirect = "",
    utm_campaign = "",
    utm_source = "",
    utm_medium = null,
    ls = "",
    scroll_top = "",
    glance_user_id,
    gl_imp_id = null,
    type = null,
    api_key = "",
    source = "",
    roposo_user_id = "",
    live_buzz_session_id = "",
    click_impression_id = "",
    cid: content_id = "",
  } = getQueryParamObject();
  const getKeyData = (keyName: string) => {
    switch (keyName) {
      case "sId": {
        return getSID();
      }
      case "utm_src": {
        if (utm_source) sessionStorage.setItem(keyName, utm_source);
        else return sessionStorage.getItem(keyName);
        return utm_source;
      }
      case "utm_medium": {
        if (utm_medium) sessionStorage.setItem(keyName, utm_medium);
        else return sessionStorage.getItem(keyName);
        return utm_medium;
      }
      case "utm_campaign":
        if (utm_campaign) sessionStorage.setItem(keyName, utm_campaign);
        else return sessionStorage.getItem(keyName);
        return utm_campaign;
      case "api_key":
        if (api_key) sessionStorage.setItem(keyName, api_key);
        else return sessionStorage.getItem(keyName);
        return api_key;
      case "gl_imp_id":
        if (gl_imp_id) sessionStorage.setItem(keyName, gl_imp_id);
        else return sessionStorage.getItem(keyName);
        return gl_imp_id;
      case "source":
        if (source) sessionStorage.setItem(keyName, source);
        else return sessionStorage.getItem(keyName);
        return source;
      case "roposo_user_id": {
        if (roposo_user_id) sessionStorage.setItem(keyName, roposo_user_id);
        else return sessionStorage.getItem(keyName);
        return roposo_user_id;
      }
      case "live_buzz_session_id": {
        if (live_buzz_session_id) sessionStorage.setItem(keyName, live_buzz_session_id);
        else return sessionStorage.getItem(keyName);
        return live_buzz_session_id;
      }
      case "click_impression_id": {
        if (click_impression_id) sessionStorage.setItem(keyName, click_impression_id);
        else return sessionStorage.getItem(keyName);
        return click_impression_id;
      }
      case "content_id": {
        if (content_id) sessionStorage.setItem(keyName, content_id);
        else return sessionStorage.getItem(keyName);
        return content_id;
      }
    }
  };

  const finalEvent = removeUndefinedNull({
    ...event,
    or: isRoposo()
      ? "roposo"
      : isGlance() || type === "bottom_sheet" || isWebView()
      ? "glance"
      : "web",
    app_sid: getKeyData("sId"),
    ts: new Date().getTime(),
    extras: removeUndefinedNull({
      ...(event.extras || {}),
      ts: new Date().getTime(),
      glanceId: getGlanceId(),
      isDeviceLocked: isGlanceLocked(),
      appName: "ROPOSO_STORE",
      app_sid: getKeyData("sId"),
      utm_src: getKeyData("utm_src"),
      utm_medium: getKeyData("utm_medium"),
      utm_campaign: getKeyData("utm_campaign"),
      api_key: getKeyData("api_key"),
      url: router.pathname,
      view_type: type,
      gl_imp_id: getKeyData("gl_imp_id"),
      glance_user_id: glance_user_id,
      utm_redirect: utm_redirect,
      scroll_top: scroll_top,
      ls: ls,
      ...JSON.parse(sessionStorage.getItem("RNA_PREV_PAGE_DETAILS") || "{}"),
      userId: glance_user_id,
      card_source: getKeyData("source"),
      roposo_user_id: getKeyData("roposo_user_id"),
      live_buzz_session_id: getKeyData("live_buzz_session_id"), // @ts-ignore
      network_type: window?.navigator?.connection?.effectiveType,
      click_impression_id: getKeyData("click_impression_id"),
      content_id: getKeyData("content_id"),
    }),
  });
  const headers = removeUndefinedNull({
    "content-type": "application/json",
    "app-nm": isGlance()
      ? getGlanceSdkVersion()
      : getCookie(null, "client-info"),
    "app-v": isGlance()
      ? getGlanceSdkVersion()
      : getCookie(null, "client-info"),
    env: isRoposo()
      ? "roposo-pwa_2.0"
      : isGlance() || isWebView()
      ? "pwa_2.0"
      : "web",
    plt: isGlance()
      ? "android"
      : getCookie(null, "platform")
      ? getCookie(null, "platform")
      : "web",
    uid: getUID(),
    gadvid: getGpId(),
  });
  try {
    trackEventForInmobi(EventTypeInt.SHOPTAB, finalEvent, headers, true); // last param {isShopTabEvent} is for triggering shoptab events via fetch api
  }
  catch (e) {
    console.log("Failed trackEventForInmobi", e);
  }

};

export const triggerClickSessionStartEvent = (router: any) => {
  if (!sessionStorage.getItem("clickSession")) {
    const ts = new Date().getTime();

    const clickSessionId = uuidv4();
    sessionStorage.setItem(
      "clickSession",
      JSON.stringify({ id: clickSessionId, ts })
    );

    const eventData = {
      [ShoptabEvents.eventType]: "impression",
      [ShoptabEvents.pageContext]: getCurrentPageName(router),
      [ShoptabEvents.action]: "click_session_start",
      [ShoptabEvents.timestamp]: ts,
      [ShoptabEvents.extras]: {
        [ShoptabEvents.clickSessionId]: clickSessionId,
      },
    };

    try {
      trackEventForShoptab(router, eventData);
    } catch (err) {
      console.error(err);
    }
  }
};

export const triggerClickSessionEndEvent = (router: any) => {
  const ts = new Date().getTime();
  const clickSession = getClickSessionInfo();

  const eventData = {
    [ShoptabEvents.eventType]: "impression",
    [ShoptabEvents.pageContext]: getCurrentPageName(router),
    [ShoptabEvents.action]: "click_session_end",
    [ShoptabEvents.timestamp]: ts,
    [ShoptabEvents.extras]: {
      [ShoptabEvents.clickSessionId]: clickSession?.id,
      [ShoptabEvents.duration]: ts - clickSession?.ts,
    },
  };

  try {
    trackEventForShoptab(router, eventData);
    sessionStorage.removeItem("clickSession");
  } catch (err) {
    console.error(err);
  }
};

export const triggerPageSessionStartEvent = (router: any) => {
  const ts = new Date().getTime();
  const ctx = getCurrentPageName(router);
  const clickSession = getClickSessionInfo();

  const pageSessionId = uuidv4();
  sessionStorage.setItem(
    "pageSession",
    JSON.stringify({ id: pageSessionId, ts, ctx })
  );

  const eventData = {
    [ShoptabEvents.eventType]: "impression",
    [ShoptabEvents.pageContext]: ctx,
    [ShoptabEvents.action]: "page_session_start",
    [ShoptabEvents.timestamp]: ts,
    [ShoptabEvents.extras]: {
      [ShoptabEvents.clickSessionId]: clickSession?.id,
      [ShoptabEvents.pageSessionId]: pageSessionId,
    },
  };

  try {
    trackEventForShoptab(router, eventData);
  } catch (err) {
    console.error(err);
  }
};

export const triggerPageSessionEndEvent = (router: any) => {
  const ts = new Date().getTime();
  const clickSession = getClickSessionInfo();
  const pageSession = getPageSessionInfo();

  const eventData = {
    [ShoptabEvents.eventType]: "impression",
    [ShoptabEvents.pageContext]: getCurrentPageName(router),
    [ShoptabEvents.action]: "page_session_end",
    [ShoptabEvents.timestamp]: new Date().getTime(),
    [ShoptabEvents.extras]: {
      [ShoptabEvents.clickSessionId]: clickSession?.id,
      [ShoptabEvents.pageSessionId]: pageSession?.id,
      [ShoptabEvents.duration]: ts - pageSession?.ts,
    },
  };

  try {
    trackEventForShoptab(router, eventData);
    sessionStorage.removeItem("pageSession");
  } catch (err) {
    console.error(err);
  }
};

export const getUTMParams = () => {
  const {
    glance_user_id,
    utm_source = sessionStorage.getItem('utm_src') || "",
    utm_medium = sessionStorage.getItem('utm_medium') || "",
    utm_campaign = sessionStorage.getItem('utm_campaign') || "",
    api_key = sessionStorage.getItem('api_key') || "",
    gl_imp_id = sessionStorage.getItem('gl_imp_id') || "",
    source = sessionStorage.getItem('source') || "DEFAULT",
    roposo_user_id = sessionStorage.getItem('roposo_user_id') || "",
    live_buzz_session_id = sessionStorage.getItem('live_buzz_session_id') || "",
    click_impression_id = sessionStorage.getItem('click_impression_id') || "",
    content_id = sessionStorage.getItem('content_id') || "",
  } = getQueryParamObject();
  
  return removeUndefinedNull({
    glance_user_id,
    utm_source,
    utm_medium,
    utm_campaign,
    api_key,
    gl_imp_id,
    source,
    roposo_user_id,
    glanceId: getGlanceId(),
    live_buzz_session_id,
    click_impression_id,
    content_id,
  });
};
