import {COOKIES, getCookie, getCookieDomain} from "./cookies";
import {NextPageContext} from "next";

export const isUserLoggedIn = (ctx?: NextPageContext | null | undefined) => {
  const domain = ctx ? getCookieDomain(ctx?.req?.headers?.host || "") : '';
  return !!getCookie(ctx, COOKIES.shop101Session, domain);
};

export const userId = (ctx?: NextPageContext | null | undefined) => {
  const domain = ctx ? getCookieDomain(ctx?.req?.headers?.host || "") : '';
  return getCookie(ctx, COOKIES.shop101Session, domain) ? JSON.parse(getCookie(ctx, COOKIES.shop101Session, domain)).userId : 0;
};

export const authToken = (ctx?: NextPageContext | null | undefined) => {
  return getCookie(ctx, 'shop101Session') ? JSON.parse(getCookie(ctx, 'shop101Session')).authToken : '';
};

export const userName = (ctx?: NextPageContext | null | undefined) => {
  const domain = ctx ? getCookieDomain(ctx?.req?.headers?.host || "") : '';
  return getCookie(ctx, COOKIES.shop101Session, domain) ? JSON.parse(getCookie(ctx, COOKIES.shop101Session, domain)).userName : '';
};

export const userEmail = (ctx?: NextPageContext | null | undefined) => {
  const domain = ctx ? getCookieDomain(ctx?.req?.headers?.host || "") : '';
  return getCookie(ctx, COOKIES.shop101Session, domain) ? JSON.parse(getCookie(ctx, COOKIES.shop101Session, domain)).userEmail : '';
};

export const userPhone = (ctx?: NextPageContext | null | undefined) => {
  const domain = ctx ? getCookieDomain(ctx?.req?.headers?.host || "") : '';
  return getCookie(ctx, COOKIES.shop101Session, domain) ? JSON.parse(getCookie(ctx, COOKIES.shop101Session, domain)).userPhone : '';
};

export const defaultAddress = (ctx?: NextPageContext | null | undefined) => {
  const domain = ctx ? getCookieDomain(ctx?.req?.headers?.host || "") : '';
  return getCookie(ctx, COOKIES.shop101Session, domain) ? JSON.parse(getCookie(ctx, COOKIES.shop101Session, domain)).defaultAddress : '';
};

export const redirectRoposoLogin = (ctx?: NextPageContext | null | undefined) => {
  return getCookie(ctx, COOKIES.redirectRoposoLogin) === 'true';
};
