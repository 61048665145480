import axios  from "axios";
import {NextPageContext} from "next";
import {COOKIES, deleteCookie, getCookie, getCookieDomain} from "utils/cookies";
import {setGlobalConfig} from "axios-logger";
import {authToken} from 'utils/auth';
import {showError, showGenericError} from "utils/alert";
import logger from 'utils/logger';

interface ApiCallParams {
  url: string,
  method: "get" | "put" | "post" | "delete",
  data?: any,
  headers?: { [key: string]: string },
  ctx?: NextPageContext | null
}

setGlobalConfig({
  prefixText: 'your prefix',
  dateFormat: 'HH:MM:ss',
  status: false,
  headers: true,
});

const instance = axios.create();

if (typeof window === 'undefined') {
  // instance.interceptors.request.use((value: InternalAxiosRequestConfig<any>) => value, AxiosLogger.errorLogger);
  // instance.interceptors.response.use(AxiosLogger.responseLogger, AxiosLogger.errorLogger);
  instance.interceptors.request.use((config) => {
      logger.info(`Axios Request - ${config.method?.toUpperCase()} ${config.url}`, {
        headers: config.headers,
        data: JSON.stringify(config.data),
      });
      return config;
    },
    (error) => {
      logger.error('Axios Request Error:', { error });
      return Promise.reject(error);
    });
  instance.interceptors.response.use((response) => {
      logger.info(`Axios Response - ${response.config.method?.toUpperCase()} ${response.config.url}`, {
        status: response.status,
        headers: response.headers,
        data: JSON.stringify(response.data),
      });
      return response;
    },
    (error) => {
      logger.error('Axios Response Error:', { error });
      return Promise.reject(error);
    });
} else {
  instance.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if (error.response?.status === 401) {
      deleteCookie(null, COOKIES.shop101Session);
    }
    if (error.response?.data?.status) {
      (async () => {
        await showError(error.response?.data?.status);
      })();
    } else {
      (async () => {
        await showGenericError();
      })();
    }
    // eslint-disable-next-line no-undef
    return Promise.reject(error);
  });
}


export const apiCall = ({url, method, data, ctx, headers}: ApiCallParams) => {
  const domain = ctx ? getCookieDomain(ctx?.req?.headers?.host || "") : '';
  let headers_ = {
    'x-deviceid': ctx?.query?.userid?.toString() || getCookie(ctx, COOKIES.deviceId) || '',
    'x-external-token': ctx?.query?.externalappjwt?.toString() || getCookie(ctx, COOKIES.roposoLoginJwt, domain) || getCookie(ctx, COOKIES.externalAppJwt, domain) || '',
    'x-external-app-name': ctx?.query?.externalappname?.toString() || getCookie(ctx, COOKIES.externalAppName) || 'roposo',
    'x-device-type': 'web',
    'x-web-nginx-request-id': ctx?.req?.headers['x-nginx-request-id']?.toString() || "",
    'x-auth-token': authToken(),
  };
  if (!headers_['x-auth-token']) {
    delete headers_['x-auth-token'];
  }
  if (headers) {
    headers_ = Object.assign(headers_, headers)
  }

  return instance({
    method,
    url,
    data,
    headers: headers_
  })
    .catch(e => {
      if (typeof window === 'undefined') {
        logger.error(e);
      } else {
        console.log(e);
      }
      throw e;
    });
}

export const paramsToString = (paramsObj: any) => {
  return new URLSearchParams(paramsObj).toString();
}
