import nookies from "nookies";
import {NextPageContext} from "next";

export const COOKIES = {
  themeId: 'themeId',
  deviceId: 'shop101DeviceKey',
  roposoDeviceId: 'deviceId',
  lastVisitedStoreId: 'shop101LastVisitedStoreId',
  externalAppJwt: 'externalAppJwt',
  src: 'src',
  sessionId: 'sessionid',
  streamId: 'streamid',
  storyId: 'storyid',
  externalCreatorId: 'externalCreatorId',
  isBuyNow: 'isBuyNow',
  buyNowVariantId: 'buyNowVariantId',
  buyNowVariantQuantity: 'buyNowVariantQuantity',
  externalAppName: 'externalAppName',
  roposoExternalAppName: 'sourceName',
  hideRoposoLoader: 'hideRoposoLoader',
  lastVisitedStoreHandle: 'shop101LastVisitedStore',
  roposoLoginJwt: 'jwe-shop101-token',
  shop101Session: 'shop101Session',
  roposoStoreId: 'roposoStoreId',
  shop101SelectedAddressId: 'shop101SelectedAddressId',
  shop101DeliveryPincode: 'shop101DeliveryPincode',
  shop101CartHash: 'shop101CartHash',
  orderValue: 'shop101OrderValue',
  orderId: 'shop101OrderId',
  redirectRoposoLogin: 'redirectRoposoLogin',
  sugar: 'sugar',
  spice: 'spice',
  userIdUsr: 'user_id_usr',
  platform: 'platform',
  clientInfo: 'client-info',
  roposoUserId: 'roposoUserId',
  gpid: 'gpid',
  sId: 'sId'
}

export const setCookie = (ctx: NextPageContext | null | undefined, key: string, value: string, domain?: string) => {
  return nookies.set(ctx, key, value, {
    domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN || domain,
    httpOnly: false,
    path: '/',
    encode: (v: any) => v,
  });
}

export const getCookie = (ctx: NextPageContext | null | undefined, key: string, domain?: string) => {
  return nookies.get(ctx, {
    domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN || domain,
    httpOnly: false,
    path: '/',
  })[key];
}

export const deleteCookie = (ctx: NextPageContext | null | undefined, key: string, domain?: string) => {
  return nookies.destroy(ctx, key, {
    domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN || domain,
    httpOnly: false,
    path: '/',
    encode: (v: any) => v,
  });
}

export const getCookieDomain = (hostname: string) => {
  if (/localhost/.test(hostname)) {
    return 'localhost';
  }
  return hostname?.replace(/^.+(\..+\..+)$/, '$1');
}
